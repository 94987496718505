// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-dato-cms-blog-post-slug-jsx": () => import("./../../../src/pages/{datoCmsBlogPost.slug}.jsx" /* webpackChunkName: "component---src-pages-dato-cms-blog-post-slug-jsx" */),
  "component---src-pages-dato-cms-book-slug-jsx": () => import("./../../../src/pages/{datoCmsBook.slug}.jsx" /* webpackChunkName: "component---src-pages-dato-cms-book-slug-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */)
}

